import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SkeletonModule } from 'primeng/skeleton';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MobxAngularModule } from 'mobx-angular';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipePipe } from './pipes/safe-pipe.pipe';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VimeModule } from '@vime/angular';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { CreatedByRefPipe } from './pipes/created-by-ref.pipe';

import { HeaderComponent } from './shared/header/header.component';
import { Header2Component } from './shared/header2/header2.component';
import { FooterComponent } from './shared/footer/footer.component';
import { WeAreSocialComponent } from './shared/we-are-social/we-are-social.component';

import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ManagementTeamComponent } from './pages/management-team/management-team.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { EventsComponent } from './pages/events/events.component';
import { NewsDetailsComponent } from './pages/details-pages/news-details/news-details.component';
import { AnnouncementDetailsComponent } from './pages/details-pages/announcement-details/announcement-details.component';
import { EventDetailsComponent } from './pages/details-pages/event-details/event-details.component';

import { FrontMainLayoutComponent } from './layouts/front-main-layout/front-main-layout.component';
import { FrontMain2LayoutComponent } from './layouts/front-main2-layout/front-main2-layout.component';

import { BlockCardComponent } from './components/cards/block-card/block-card.component';
import { AnnouncementCardComponent } from './components/cards/announcement-card/announcement-card.component';
import { NewsCardComponent } from './components/cards/news-card/news-card.component';
import { EventsCardComponent } from './components/cards/events-card/events-card.component';
import { PartnershipCardComponent } from './components/cards/partnership-card/partnership-card.component';
import { ManagementTeamCardComponent } from './components/cards/management-team-card/management-team-card.component';

import { IntroductionBlockComponent } from './components/introduction-block/introduction-block.component';
import { EventOverviewComponent } from './components/event-overview/event-overview.component';
import { SecondDescriptionBlockComponent } from './components/second-description-block/second-description-block.component';
import { ThirdDescriptionBlockComponent } from './components/third-description-block/third-description-block.component';
import { FourthDescriptionBlockComponent } from './components/fourth-description-block/fourth-description-block.component';
import { CardSwiperComponent } from './components/card-swiper/card-swiper.component';
import { IntroductionPageBlockComponent } from './components/introduction-page-block/introduction-page-block.component';
import { BarcodeProfileComponent } from './pages/barcode-profile/barcode-profile.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WeAreSocialComponent,
    HomeComponent,
    FrontMainLayoutComponent,
    NotFoundPageComponent,
    IntroductionBlockComponent,
    EventOverviewComponent,
    SecondDescriptionBlockComponent,
    ThirdDescriptionBlockComponent,
    FourthDescriptionBlockComponent,
    CardSwiperComponent,
    SafePipePipe,
    Header2Component,
    FrontMain2LayoutComponent,
    NewsComponent,
    IntroductionPageBlockComponent,
    BlockCardComponent,
    AboutUsComponent,
    ManagementTeamComponent,
    PartnershipComponent,
    AnnouncementComponent,
    EventsComponent,
    AnnouncementCardComponent,
    NewsCardComponent,
    EventsCardComponent,
    PartnershipCardComponent,
    ManagementTeamCardComponent,
    NewsDetailsComponent,
    CreatedByRefPipe,
    AnnouncementDetailsComponent,
    EventDetailsComponent,
    BarcodeProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    MobxAngularModule,
    NgPipesModule,
    YouTubePlayerModule,
    VimeModule,
    SkeletonModule,
    LazyLoadImageModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: ScrollHooks
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

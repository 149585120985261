import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementStore } from 'src/app/stores/announcement.store';

@Component({
  selector: 'app-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.scss']
})
export class AnnouncementDetailsComponent implements OnInit {

  defaultImage = '../../../../assets/images/loading.gif';

  constructor(
    public store: AnnouncementStore,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.getAnnouncementDetails(params.key)
    });
  }

  getImageFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => item?.fileType?.includes('image'));
  }

  getPDFFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => item?.fileType?.includes('pdf'));
  }

  getDownloadableFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => !item?.fileType?.includes('pdf') && !item?.fileType?.includes('image'));
  }

}

<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'សេចក្តីជូនដំណឹង'" [backgroundColor]="'linear-gradient( 177.8deg,  rgba(255,128,0,1) 9.2%, rgba(232,211,59,1) 86.8% )'"></app-introduction-page-block>

    <div class="cardsContainer">
        <app-announcement-card *ngFor="let item of this.announcementStore.data" [title]="item.name" [image]="item.files[0].downloadUrl" [key]="item.key"></app-announcement-card>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.announcementStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.announcementStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.announcementStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    </div>
</div>
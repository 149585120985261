<header id="header">
    <div class="header_container">

        <div class="header_image">
            <div class="logoContainer" [routerLink]="['/']">
                <img src="/assets/images/NGS.png" id="header_image">
            </div>
            <div class="logoTitle" id="logoTitle" >
                <p>វិទ្យាល័យព្រះស៊ីសុវត្ថិ</p>
                <p>សាលារៀនជំនាន់ថ្មី</p>
            </div>
        </div>

        <div class="header_nav">
            <div class="header_navbars">

                <div class="top_navbar">
                    <ul>
                        <li><a href="https://ngsclassroom.preahsisowath.edu.kh/" target="_blank" class="top_navbar__menuItem">NGS Classroom</a></li>
                        <li><a href="http://onelink.to/rfw6jr" target="_blank" class="top_navbar__menuItem">Cam Reading</a></li>
                        <li><a href="http://onelink.to/rfw6jr/" target="_blank" class="top_navbar__menuItem">NGS E-Library</a></li>
                    </ul>
                </div>

                <div class="bottom_navbar">
                    <ul>
                        <li>
                            <a href="/announcement" class="bottom_navbar__menuItem">សេចក្តីជូនដំណឹង</a>
                        </li>
                        <li>
                            <a href="/event" class="bottom_navbar__menuItem">ព្រឹត្តិការណ៍</a>
                        </li>
                        <li>
                            <a href="/news" class="bottom_navbar__menuItem">ព័ត៌មាន</a>
                        </li>
                        <li>
                            <a href="/management-team" className="bottom_navbar__menuItem">គណៈនាយក</a>
                        </li>
                        <li>
                            <a href="/partnership" class="bottom_navbar__menuItem">ដៃគូរសហការ</a>
                        </li>
                        <li>
                            <a href="/about-us" class="bottom_navbar__menuItem">អំពីសាលា</a>
                        </li>
                    </ul>
                </div>

            </div>

            <!-- <div class="header_search">
                <img src="/assets/images/icons8-search-64.png">
            </div> -->
        </div>
        
        <div class="hamburgur-menu-icon">
            <div class="wrapper-menu" (click)="this.hamburgurClicked()" [ngClass]="this.Closed === true ? 'open' : ''">
                <div class="line-menu half start" id="line-menu1"></div>
                <div class="line-menu" id="line-menu2"></div>
                <div class="line-menu half end" id="line-menu3"></div>
            </div>
        </div>
    </div>

    <div class="dropdown_header_for_navbar_display_none" [ngClass]="this.Closed === true ? 'dropdown_header_for_navbar_clicked' : 'dropdown_header_for_navbar'">
        <a href='https://ngsclassroom.preahsisowath.edu.kh/' target="_blank" routerLinkActive="active">NGS Classroom</a>
        <a href="http://onelink.to/rfw6jr/" target="_blank" routerLinkActive="active">Cam Reading</a>
        <a href="http://onelink.to/rfw6jr/" routerLinkActive="active">NGS E-Library</a>
        <a [routerLink]="['/announcement']" routerLinkActive="active">សេចក្តីជូនដំណឹង</a>
        <a [routerLink]="['/event']" routerLinkActive="active">ព្រឹត្តិការណ៍</a>
        <a [routerLink]="['/news']" routerLinkActive="active">ព័ត៌មាន</a>
        <a [routerLink]="['/management-team']" routerLinkActive="active">គណៈនាយក</a>
        <a [routerLink]="['/partnership']" routerLinkActive="active">ដៃគូរសហការ</a>
        <a [routerLink]="['/about-us']" routerLinkActive="active">អំពីសាលា</a>
    </div>
</header>
import { Component, OnInit } from '@angular/core';
import { SchoolProgramStore } from 'src/app/stores/school_program.store';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-card-swiper',
  templateUrl: './card-swiper.component.html',
  styleUrls: ['./card-swiper.component.scss']
})
export class CardSwiperComponent implements OnInit {

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 20,

    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      650: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 30
      },
    }
  };

  constructor(
    public schoolProgramStore: SchoolProgramStore
  ) { }

  ngOnInit(): void {
    this.schoolProgramStore.getSchoolPrograms();
  }

}
import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { KeywordGeneratorService } from "../services/generator/keyword-generator.service";
import { pushToArray } from "../services/data/data-manipulation.service";

@Injectable({providedIn:'root'})
export class ManagementTeamStore {
  @observable public arrManagementTeam: Array<any> = [];
  @observable public managementTeam: any;
  
  @observable data: any = [];
  @observable loading = false;
  public lastVisible: any = null;
  @observable public fetching: boolean = false;
  @observable public searchText = null;
  @observable public filter = null;

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  @action
  getManagementTeams() {
    this.loading = true;
    this.afs.collection('stores').doc('ngs').collection('apps_management_team', ref => ref.orderBy('created_at', 'desc').limit(3)).valueChanges().subscribe((data: Array<any>) => {
      this.arrManagementTeam = data.reverse();
      this.loading = false;
    })
  }

  @action 
  async getManagementTeams_2(courseKey: string) {
    try {
      this.loading = true;
      const data = pushToArray(await this.afs.collection('apps_management_team').get().toPromise());
      this.arrManagementTeam = data;
      this.loading = false;
    } catch(error) {
      console.log(error)
    }
  }

  @action
  async fetchData() {
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;

    const ref = this.lazyDataRef(this.lastVisible);

    ref.auditTrail().subscribe();
    ref.snapshotChanges().subscribe(response => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }

      console.log(this.data)

      this.loading = false
      this.fetching = false;
    }, error => {
      this.loading = false;
    });
  }

  @action
  async fetchDataMore() {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data());
      }

      console.log(this.data)

      this.fetching = false;
    }, error => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any) {
    return this.afs.collection('stores').doc('ngs').collection<any>("apps_management_team", ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(10);

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      
      return condition
    })
  }
}

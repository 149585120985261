import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsStore } from 'src/app/stores/news.store';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {

  defaultImage = '../../../../assets/images/loading.gif';

  constructor(
    public newsStore: NewsStore,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.newsStore.getNewsDetails(params.key)
    });
  }

  ngOnInit() {
  
  }

  getImageFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => item?.fileType?.includes('image'));
  }

  getPDFFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => item?.fileType?.includes('pdf'));
  }

  getDownloadableFiles(arrFiles: Array<any>) {
    if (!arrFiles) return [];
    return arrFiles.filter((item) => !item?.fileType?.includes('pdf') && !item?.fileType?.includes('image'));
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-card',
  templateUrl: './block-card.component.html',
  styleUrls: ['./block-card.component.scss']
})
export class BlockCardComponent implements OnInit {

  @Input() title;
  @Input() subTitle;
  @Input() image;
  @Input() style;
  @Input() style2;
  @Input() style3;

  constructor() { }

  ngOnInit(): void {
  }

}

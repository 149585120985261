import { Component, OnInit } from '@angular/core';
import { EventsStore } from 'src/app/stores/events.store';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(
    public eventStore: EventsStore
  ) { }

  ngOnInit(): void {
    this.eventStore.fetchData();
  }

}

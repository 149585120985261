<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'ព័ត៌មាន'" [backgroundColor]="' linear-gradient(109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2%)'"></app-introduction-page-block>
    
    <div class="cardsContainer">
        <app-news-card *ngFor="let item of this.newsStore.data" [title]="item.name" [image]="item.files[0].downloadUrl" [key]="item.key"></app-news-card>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.newsStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.newsStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.newsStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    </div>
</div>
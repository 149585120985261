import { Component, OnInit } from '@angular/core';
import { ManagementTeamStore } from 'src/app/stores/management_team.store';

@Component({
  selector: 'app-management-team',
  templateUrl: './management-team.component.html',
  styleUrls: ['./management-team.component.scss']
})
export class ManagementTeamComponent implements OnInit {

  constructor(
    public managementTeamStore: ManagementTeamStore
  ) { }

  ngOnInit(): void {
    this.managementTeamStore.fetchData();
  }

}

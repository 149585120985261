import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-barcode-profile',
  templateUrl: './barcode-profile.component.html',
  styleUrls: ['./barcode-profile.component.scss']
})
export class BarcodeProfileComponent implements OnInit {

  isFound = false;

  kh_name: any;
  en_name: any;
  gender: any;
  dob: any;
  personal_tel: any;
  puc_id: any;
  class: any;
  academic_year: any;
  image: any = '../../../assets/images/boy.png';

  constructor(
    private  router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      try {
        const apiUrl = `https://us-central1-ngscloudv3.cloudfunctions.net/fetchProfiles?key=${params.key}&type=${params.type}`;
        // const apiUrl = 'https://us-central1-ngscloudv3.cloudfunctions.net/fetchProfiles?key=03qRRnYItbhOouFsYi6X&type=student';
  
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
  
        const options = {
          method: 'GET',
          headers: myHeaders,
        };
    
        fetch(apiUrl, options).then(res => res.json()).then((result) => {
          console.log(result)

          if (!result) {
            this.isFound = false;
            return;
          }

          this.isFound = true;

          let program_academic_key = result.data.reportDocs?.program_academic?.key;
          
          this.kh_name = result.data.reportDocs?.khmer_full_name || 'N/A';
          this.en_name = result.data.reportDocs?.full_name || 'N/A';
          this.gender = result.data.reportDocs?.gender?.text || 'N/A';
          this.dob = result.data.reportDocs?.dob?._seconds || 0;
          this.personal_tel = result.data.reportDocs?.mobile_phone || 'N/A';
          this.puc_id = result.data.reportDocs?.puc_id || result.data.reportDocs?.code || 'N/A';
          this.class = program_academic_key === undefined ? 'N/A' : result.data.reportDocs[program_academic_key]?.name;
          this.academic_year = result.data.reportDocs?.classroomProgram?.category?.name || 'N/A';
          this.image = result.data.reportDocs?.card_fileurl;

          let dtDOB = new Date(this.dob * 1000);
          this.dob = `${dtDOB.getDate()} / ${dtDOB.getMonth() + 1} / ${dtDOB.getFullYear()}`; 

          if (!this.image) {
            this.image = this.gender === 'ប្រុស' || this.gender === 'Male' ? '../../../assets/images/boy.png' : '../../../assets/images/Girl.png';
          } 
        });
      } catch(error) {
        alert(`Oops! ${error}`)
      }
    });
  }
}

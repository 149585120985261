import { Component, OnInit } from '@angular/core';
import { ManagementTeamStore } from 'src/app/stores/management_team.store';

@Component({
  selector: 'app-fourth-description-block',
  templateUrl: './fourth-description-block.component.html',
  styleUrls: ['./fourth-description-block.component.scss']
})
export class FourthDescriptionBlockComponent implements OnInit {

  constructor(
    public managementTeamStore: ManagementTeamStore
  ) { }

  ngOnInit(): void {
    this.managementTeamStore.getManagementTeams();
  }

}

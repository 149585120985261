import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-description-block',
  templateUrl: './third-description-block.component.html',
  styleUrls: ['./third-description-block.component.scss']
})
export class ThirdDescriptionBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

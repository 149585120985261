<div id="fourthDescriptionContainer">

    <div class="descriptionContentContainer">
        <div class="descriptionTitle">
            <span class="light">គណៈនាយក</span><br/>
            <span class="bold">សាលារៀនជំនាន់ថ្មី</span>
        </div>
        <div class="contentDetailContainer">

            <div class="managementTeamContainer_lazy" *ngIf="this.managementTeamStore.loading">
                <div class="imageContainer">
                    <p-skeleton shape="circle" size="120px"></p-skeleton>
                </div>
                <div class="descriptionContainer">
                    <p-skeleton width="100%" height="35px"></p-skeleton>
                    <div style="margin-top: 10px;"></div>
                    <p-skeleton width="100%" height="20px"></p-skeleton>
                </div>
            </div>

            <div class="managementTeamContainer_lazy" *ngIf="this.managementTeamStore.loading">
                <div class="imageContainer">
                    <p-skeleton shape="circle" size="120px"></p-skeleton>
                </div>
                <div class="descriptionContainer">
                    <p-skeleton width="100%" height="35px"></p-skeleton>
                    <div style="margin-top: 10px;"></div>
                    <p-skeleton width="100%" height="20px"></p-skeleton>
                </div>
            </div>

            <div class="managementTeamContainer_lazy" *ngIf="this.managementTeamStore.loading">
                <div class="imageContainer">
                    <p-skeleton shape="circle" size="120px"></p-skeleton>
                </div>
                <div class="descriptionContainer">
                    <p-skeleton width="100%" height="35px"></p-skeleton>
                    <div style="margin-top: 10px;"></div>
                    <p-skeleton width="100%" height="20px"></p-skeleton>
                </div>
            </div>

            <div class="managementTeamContainer" *ngFor="let item of this.managementTeamStore.arrManagementTeam">
                <div class="imageContainer">
                    <img src="{{ item?.files[0].downloadUrl }}">
                </div>
                <div class="descriptionContainer">
                    <h1>{{ item?.khName }}</h1>
                    <h2>{{ item?.khTitle }}</h2>
                </div>
            </div>

        </div>
    </div>

</div>
<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'អំពីសាលា'" [backgroundColor]="'linear-gradient( 102.1deg,  rgba(96,221,142,1) 8.7%, rgba(24,138,141,1) 88.1% )'"></app-introduction-page-block>

    <div class="aboutUsContainer">

        <p>
            សាលារៀនជំនាន់ថ្មីនៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ(NGS)គឺជាគំរូសាលារៀនមួយស្ថិតនៅក្នុងវិទ្យាល័យ​​ធំជាងគេនិងមានវ័យចំណាស់ជាងគេនៅក្នុងប្រទេសកម្ពុជាមានអាយុកាលជាង១១៣ឆ្នាំ។សាលារៀននេះជាលទ្ធផល
            មួយទៀតនៃការធ្វើកំណែទម្រង់ស៊ីជម្រៅលើវិស័យអប់រំ ដែលបានសម្ភោធដាក់ឱ្យដំណើរការ​នៅទី២៣ ខែ មិថុនា ឆ្នាំ ២០១៦ មាន២៤ថ្នាក់រៀនចែកជាបន្ទប់រៀនគីមីវិទ្យាបំពាក់ដោយ​សម្ភារៈ បរិក្ខារពិសោធ
            ចំនួន០៣បន្ទប់ បន្ទប់រៀនរូបវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខារពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀន​​​​​ជី​វវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខាពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀនកុំព្យូទ័របំពាក់ដោយ​ម៉ាស៊ីនកុំព្យូទ័រ​​ទំនើប
            ចំនួន០២បន្ទប់ស្មើ៦០គ្រឿងបន្ទប់គណិតវិទ្យា ០៤ បន្ទប់រៀន​ពោពេញ​ដោយសម្ភារឧប្បទេស ចាប់ពីថ្នាក់ទី ៧ ដល់ថ្នាក់ទី​១២ ​ គ្រូបង្រៀន សរុបចំនួន ៥៨នាក់ក្នុងនោះស្រី ៣០នាក់។ នៅក្នុងឆ្នាំសិក្សា 
            ២០១៧-២០១៨ សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ​ មានសិស្ស សរុបចំនួន៧៦៣នាក់ ក្នុងនោះស្រី៣៣៩នាក់។ សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ បានអនុវត្តន៏គោលវិធីសិក្សាថ្មីពេញលេញ
            ដំបូងគេនៅសាលារដ្ឋគឺSTEM-Edដែលទាក់ទាញសិស្សពូកែៗ​ចូលរៀននិងប្រែក្លាយសិស្សដែលមិនចូលចិត្តរៀនវិទ្យាសាស្ត្រឱ្យចាប់អារម្មណ៏និងរៀនពូកែដែរ។ក្រៅពី​ការរៀន​ក្នុងថ្នាក់សិស្សមានការស្រាវជ្រាវជាច្រើន
            ដែលមានឧត្តមភាពនិងការប្រកួតប្រជែងនិងសហការ​ជាមួយគ្រឹះស្ថានជាតិនិងអន្តរជាតិ។បច្ចុប្បន្នសាលារៀនជំនាន់ថ្មីនៃវិទ្យាល័យព្រះស៊ីសុវត្ថិកំពុងធ្វើការអភិវឌ្ឍន៍របស់ខ្លួនក្រោមកិច្ចសហប្រត្តិបត្តិការជាមួយដៃ
            គូរជាតិនិងអន្តរជាតិមួយចំនួននៅប្រទេសថៃកូរ៉េចិនជប៉ុនសហរដ្ឋអាមេរិចអូស្រ្តាលី...។
        </p>
            
        <ol>
            <li>
                <h4>ចក្ខុវិស័យ</h4>  

                <p>
                    ដើម្បីឆ្លើយតបទៅនឹងចក្ខុវិស័យរបស់រាជរដ្ឋាភិបាលកម្ពុជា ២០៣០ និងការគោលដៅអភិវឌ្ឍន៍​ប្រកប​ដោយចីរភាព ២០៣០ សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិមានចក្ខុវិស័យ “ប្រែក្លាយ សាលា រៀនជំនាន់ថ្មី
                    នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ ជាវិទ្យាល័យមានឧត្តមភាពឈានមុខគេ របស់កម្ពុជាលើការបង្រៀន (ទាំង ឥរិយាបថ និងចំណេះដឹង) ការស្រាវជ្រាវនៅមធ្យមសិក្សា និងការផ្តល់សេវាសហគមន៍”។
                </p>
            </li>

            <li>
                <h4>បេសកកម្ម</h4>  

                <p>
                    ដើម្បីឆ្លើយតបទៅនឹងចក្ខុវិស័យនេះ សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិបានកំណត់បេសកកម្មរបស់ខ្លួនចំនួនប្រាំពីរ៖ 
                </p>

                <ul>
                    <li>អភិវឌ្ឍសមត្ថភាព (ចំណេះដឹង ជំនាញទន់ ភាពថ្លៃថ្នូរ និងអត្តចរិក) របស់សិស្សតាមរយៈការអប់រំនូវគ្រប់មធ្យោបាយ(ក្នុងថ្នាក់ ក្រៅថ្នាក់)  និងផ្តល់អោយសិស្សនូវជំនាញសហគ្រិនភាព ជំនាញច្នៃប្រឌិត​​សមត្ថភាពយល់ដឹងអន្តរវប្បធម៌ និងជំនាញរកនិងបង្កើតការងារ។</li>
                    <li>ជាគ្រឹះស្ថាននៃការស្រាវជ្រាវលើវិស័យអប់រំកម្រិតខ្ពស់ និងបច្ចេកវិទ្យាសារទូរគមនាគមន៍ (ICT)​សម្រាប់មធ្យមសិក្សា។</li>
                    <li>ជាគ្រឹះស្ថានឈានមុខសម្រាប់ការអនុវត្តកម្មវិធីសិក្សា STEM-Ed។</li>
                    <li>បង្កើនការស្រាវជ្រាវនិងអភិវឌ្ឍន៍គ្រប់កម្មវិធីសិក្សាថ្នាក់ជាតិ។</li>
                    <li>ជាគ្រឹះស្ថានឈានមុខក្នុងការស្រាវជ្រាវ ពិគ្រោះយោបល់ និងការផ្តល់សេវាដល់សហគមន៍ឱ្យល្អប្រសើរ។</li> 
                    <li>ជាមជ្ឈមណ្ឌលនៃអភិបាលកិច្ចល្អលើសេវារដ្ឋបាលសាលារៀន និងការគ្រប់គ្រងហិរញ្ញវត្ថុដែលមានតម្លាភាព និងគណនេយ្យភាពសម្រាប់សិស្ស​ បុគ្គលិក និងអ្នកពាក់ព័ន្ធ។</li>
                    <li>ជាមជ្ឈមណ្ឌលប្រឹក្សាអាជីពសម្រាប់តម្រង់ទិសវិជ្ជាជីវរបស់សិស្ស។</li>
                </ul>
            </li>

            <li>
                <h4>សកម្មភាព STEM-Ed នាពេលបច្ចុប្បន្ន</h4>  

                <p>
                    សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ (NGS) បាននឹងកំពុងអនុវត្តកម្មវិធីសិក្សាថ្មី ឆ្លើយតប និងតម្រូវការរបស់សិស្សា​នុសិស្សក្នុងសតវត្សទី​២១ ដែលការសិក្សាផ្សារភ្ជាប់និងការអនុវត្ត 
                    ពិសោធន៏ ស្រាវជ្រាវរកប្រភពធនធានពីខាងក្រៅសៀវភៅសិក្សាគោល។ សិស្សអាចស្វែងយល់ពីការងារចំបងៗក្រៅពីស្ដាប់គ្រូក្នុងថ្នាក់ដូចជាចេះប្រើប្រាស់បច្ចេកវិទ្យា(ICT) បំរើឱ្យការ 
                    ស្រាវជ្រាវនិងអនុវត្តន៏។ក្រៅពីការសិក្សាជាមួយគ្រូ សិស្សក៏បានបង្កើតក្លឹបសិក្សា ជាច្រើនដូចជា៖ ក្លឹបវិទ្យាសាស្ត្រ ក្លឹបវីដេអូ ក្លឹបកាសែត ....។
                </p>

                <p>
                    បច្ចុប្បន្នសាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ (NGS)បង្កើតក្របខណ្ឌស្រាវជ្រាវវិទ្យាសាស្រ្ត កម្រិតខ្ពស់នៅថ្នាក់វិទ្យាល័យ ដែលអាចទៅប្រកួតជាមួយសិស្សដទៃទៀតក្នុងស្រុក 
                    និងអន្តរជាតិមួយ ចំនួនដូចជា៖ ការប្រលងអន្តរជាតិរួមមាន៖
                </p>

                <ol>
                    <li>ប្រវត្តិវិទ្យាសាស្រ្តធម្មជាតិពិភពលោកGNHD ប្រទេស ចិន   ចាប់ផ្តើមឆ្នាំ ២០១៧ មួយលើកសិស្ស ០៧នាក់​ បានមេដាយ ប្រាក់។</li>
                    <li>National Science Children Congress ប្រទេស ឥណ្ឌា    	ចាប់ផ្តើមឆ្នាំ ២០១៦,២០១៧ សិស្សចំនួន ៨ នាក់ចូលរួម។</li>
                    <li>រូបវិទ្យាអូឡាំព្យាដអន្តរជាតិ ប្រទេសហូឡង់ចាប់ផ្តើមឆ្នាំ ២០១៧ សិស្សម្នាក់ចូលរួម</li>
                    <li>តារាវិទ្យា និងរូបវិទ្យាតារា អូឡាំព្យាដអន្តរជាតិ ប្រទេសថៃ   ចាប់ផ្តើមឆ្នាំ ២០១៧,២០១៨ សិស្ស ពីរ នាក់ ចូលរួម។</li>
                    <li>ASEAN Children Camp ប្រទេស ឥណ្ឌូនេស៊ី, ហ្វីលីពីន    	ចាប់ផ្តើមឆ្នាំ ២០១៦,២០១៧ សិស្ស ០៤នាក់ចូលរួម ទទួលបានចំណាត់ថ្នាក់លេខបួន។</li>
                    <li>International-Thai Science Fare(ITSF) ប្រទេសថៃចាប់ផ្តើមឆ្នាំ ២០១៦,២០១៧ សិស្ស ០៦នាក់ចូលរួម។</li>
                    <li>Japan Supper Science Fare (JSSF) ប្រទេសជប៉ុន  	ចាប់ផ្តើមឆ្នាំ ២០១៧ សិស្ស ០៣ នាក់ចូលរួម។</li>
                </ol>
            </li>

            <li>
                <h4>ការអភិវឌ្ឍន៍ផ្នែក STEM-Ed នាពេលអនាគត</h4>

                <p>សាលារៀនជំនាន់ថ្មី(NGS) មានគម្រោងផែនការអភិវឌ្ឍន៍ផ្នែក STEM-Ed ជាច្រើនដូចជា៖</p>

                <ul>
                    <li>ជម្រុញការស្រាវជ្រាវរបស់គ្រូ និងសិស្សលើមុខវិជ្ជា STEM-Ed ឱ្យកាន់តែទូលំទូលាយ</li>
                    <li>បង្កើតក្លឹបស្រាវជ្រាវ និងឆ្នៃបង្កើតរបស់សិស្ស</li>
                    <li>កំណែទំរង់កម្មវិធីសិក្សាផ្នែក STEM-Ed ដើម្បីឆ្លើយតបទៅនឹងគោលនយោបាយឧស្សាហកម្ម​​របស់​​	កម្ពុជា ២០១៥-២០២៥។</li>
                    <li>បង្កើតវប្បធម៌ស្រាវជ្រាវ និងការបោះពុម្ភផ្សាយផ្នែក STEM-Ed ជាស្នាដៃសិស្សនៅវិទ្យាល័យ</li>
                    <li>ធ្វើទំនើបកម្មទីពិសោធន៍ និងបង្កើតទីពិសោធន៍ថ្មីៗអោយឆ្លើយតមទៅនឹងតម្រូវការទីផ្សាការងារ</li>
                </ul>
            </li>
        </ol>

        <h1>អាសយដ្ឋាន</h1>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1470.6846949088438!2d104.92548540721613!3d11.564248118275183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3109511ed7022d13%3A0xb6486a3487c4542c!2zTkdTLiDhnoDhnrbhnprhnrfhnpnhnrbhnpvhn5DhnpnhnoDhnpjhn5LhnpjhnpzhnrfhnpLhnrfhnp_hnrbhnpvhnrbhnprhn4DhnpPhnofhn4bhnpPhnrbhnpPhn4vhnpDhn5Lhnpjhnrg!5e0!3m2!1sen!2skh!4v1615812841581!5m2!1sen!2skh" style="border:0; width: 100%; height: 500px;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</div>
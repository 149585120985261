<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'គណៈនាយក'" [backgroundColor]="'radial-gradient( circle farthest-corner at 10% 20%,  rgba(153,103,186,1) 41.2%, rgba(0,113,227,1) 90% )'"></app-introduction-page-block>

    <div class="cardsContainer">
        <app-management-team-card *ngFor="let item of this.managementTeamStore.data.reverse()" 
        [title]="item.khName" [subTitle]="item.khTitle" [image]="item.files[0].downloadUrl"></app-management-team-card>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.managementTeamStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.managementTeamStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.managementTeamStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    </div>

</div>
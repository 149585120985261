<div class="secondDescriptionContainer">
    <div class="secondDescriptionContainer_subContainer">
        <div class="secondDescriptionContainer_leftSide">
            <div class="secondDescriptionContainer_title">
                <span class="title_one">សាលារៀន</span><br/>
                <span class="title_three">របស់យើង</span>
            </div>

            <div class="secondDescriptionContainer_content">

                <div class="first-box">
                    <h4>
                        សាលារៀននេះជាលទ្ធផល មួយទៀតនៃការធ្វើកំណែទម្រង់ស៊ីជម្រៅលើវិស័យអប់រំ ដែលបានសម្ភោធដាក់ឱ្យដំណើរការ​នៅទី២៣ ខែ មិថុនា ឆ្នាំ ២០១៦ មាន២៤ថ្នាក់រៀនចែកជាបន្ទប់រៀនគីមីវិទ្យា
                        បំពាក់ដោយ​សម្ភារៈ បរិក្ខារពិសោធ ចំនួន០៣បន្ទប់ បន្ទប់រៀនរូបវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខារពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀន​​​​​ជី​វវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខាពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀន
                        កុំព្យូទ័របំពាក់ដោយ​ម៉ាស៊ីនកុំព្យូទ័រ​​ទំនើប ចំនួន០២បន្ទប់ស្មើ៦០គ្រឿងបន្ទប់គណិតវិទ្យា ០៤ បន្ទប់រៀន​ពោពេញ​ដោយសម្ភារឧប្បទេស ចាប់ពីថ្នាក់ទី ៧ ដល់ថ្នាក់ទី​១២ ​ គ្រូបង្រៀន សរុបចំនួន 
                        ៥៨នាក់ក្នុងនោះស្រី ៣០នាក់
                    </h4>
                    <p>
                        នៅក្នុងឆ្នាំសិក្សា ២០១៧-២០១៨ សាលារៀនជំនាន់ថ្មី នៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ​ មានសិស្ស សរុបចំនួន៧៦៣នាក់ ក្នុងនោះស្រី៣៣៩នាក់
                    </p>
                </div>

                <div class="second-box" *ngIf="this.videoStore.arrVideo.length >= 1 && !this.videoStore.loading">
                    <iframe width="100%" height="90%" [src]="'https://www.youtube.com/embed/' + this.getYoutubeId(this.videoStore.arrVideo[0]?.youtubeUrl) | safe:'resourceUrl'" class="youtubeEmbebed"></iframe>
                    <h1>{{ this.videoStore.arrVideo[0]?.name }}</h1>
                </div>

                <div class="second-box" *ngIf="this.videoStore.loading">
                    <p-skeleton width="100%" height="89%"></p-skeleton>
                    <div style="margin-top: 1%;"></div>
                    <p-skeleton width="100%" height="10%"></p-skeleton>
                </div>

                <div class="third-box" *ngIf="this.videoStore.arrVideo.length >= 2 && !this.videoStore.loading">
                    <iframe width="100%" height="90%" [src]="'https://www.youtube.com/embed/' + this.getYoutubeId(this.videoStore.arrVideo[1]?.youtubeUrl) | safe:'resourceUrl'" class="youtubeEmbebed"></iframe>
                    <h1>{{ this.videoStore.arrVideo[1]?.name }}</h1>
                </div>

                <div class="third-box" *ngIf="this.videoStore.loading">
                    <p-skeleton width="100%" height="89%"></p-skeleton>
                    <div style="margin-top: 1%;"></div>
                    <p-skeleton width="100%" height="10%"></p-skeleton>
                </div>

            </div>
        </div>

    </div>

</div>
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnnouncementStore } from 'src/app/stores/announcement.store';
import { EventsStore } from 'src/app/stores/events.store';
import { NewsStore } from 'src/app/stores/news.store';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss']
})
export class EventOverviewComponent implements OnInit {

  currentTimeStamp;

  constructor(
    public newsStore: NewsStore,
    public eventStore: EventsStore,
    public announcementStore: AnnouncementStore,
    private cdref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentTimeStamp = Date.now();

    this.announcementStore.getAnnoucements();
    this.newsStore.getNews();
    this.eventStore.getEvents();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getStrDate(date) {
    return date ? new Date(date).toLocaleDateString() : new Date().toLocaleDateString();
  }

}

import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { KeywordGeneratorService } from "../services/generator/keyword-generator.service";
import { pushToArray } from "../services/data/data-manipulation.service";

@Injectable({providedIn:'root'})
export class VideoStore {
  @observable public arrVideo: Array<any> = [];
  @observable public video: any;

  @observable data: any = [];
  @observable loading = false;
  public lastVisible: any = null;
  @observable public fetching: boolean = false;
  @observable public searchText = null;
  @observable public filter = null;

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  @action
  getVideos() {
    this.loading = true;
    this.afs.collection('stores').doc('ngs').collection('apps_videos', ref => ref.orderBy('created_at', 'desc').limit(2)).valueChanges().subscribe((data: any) => {
      this.arrVideo = data;
      this.loading = false;
    })
  }

  @action 
  async getVideos_2() {
    try {
      const data = pushToArray(await this.afs.collection('apps_videos').get().toPromise());
      this.arrVideo = data;
    } catch(error) {
      console.log(error)
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-team-card',
  templateUrl: './management-team-card.component.html',
  styleUrls: ['./management-team-card.component.scss']
})
export class ManagementTeamCardComponent implements OnInit {

  defaultImage = "../../../../assets/images/loading.gif";

  @Input() title;
  @Input() subTitle;
  @Input() image;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-card',
  templateUrl: './events-card.component.html',
  styleUrls: ['./events-card.component.scss']
})
export class EventsCardComponent implements OnInit {

  defaultImage = '../../../../assets/images/loading.gif';

  @Input() title;
  @Input() subTitle;
  @Input() image;
  @Input() key;

  constructor() { }

  ngOnInit(): void {
  }

}

<div id="eventOverviewContainer">
    <div class="eventsDetail_firstRow">

        <div class="eventContainer">
            <div class="eventTitleContainer">
                <h2>Announcement</h2>
                <h2 class="seeMoreLink" [routerLink]="['/announcement']">See More</h2>
            </div>

            <div class="eventContentContainer" *ngIf="!this.announcementStore.loading" [routerLink]="['/announcement/' + this.announcementStore?.arrAnnouncement[0]?.key]">
                <div class="eventContentContainer_leftSide">
                    <h1>{{ this.announcementStore?.arrAnnouncement[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'dd' }}</h1>
                    <h4>{{ this.announcementStore?.arrAnnouncement[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'MMMM'}}</h4>
                </div>
                <div class="eventContentContainer_rightSide">
                    <h4>{{ this.announcementStore?.arrAnnouncement[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'shortTime' }}</h4>
                    <p>{{ this.announcementStore?.arrAnnouncement[0]?.khName || "មិនមានការប្រកាសដំណឹង"}}</p>
                </div>
            </div>

            <div class="lazyLoadingContainer" style="width: 100%; padding: 20px 30px;" *ngIf="this.announcementStore.loading">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="80%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="50%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
            </div>
        </div>

        <div class="eventContainer">
            <div class="eventTitleContainer">
                <h2>Events</h2>
                <h2 class="seeMoreLink" [routerLink]="['/event']">See More</h2>
            </div>

            <div class="eventContentContainer" *ngIf="!this.eventStore.loading" [routerLink]="['/event/' + this.eventStore?.arrEvent[0]?.key]">
                <div class="eventContentContainer_leftSide">
                    <h1>{{ this.eventStore?.arrEvent[0]?.created_at?.toDate()  | date : 'dd' }}</h1>
                    <h4>{{ this.eventStore?.arrEvent[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'MMMM'}}</h4>
                </div>
                <div class="eventContentContainer_rightSide">
                    <h4>{{ this.eventStore?.arrEvent[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'shortTime' }}</h4>
                    <p>{{ this.eventStore?.arrEvent[0]?.name || "មិនមានព្រឹត្តិការណ៍" }}</p>
                </div>
            </div>

            <div class="lazyLoadingContainer" style="width: 100%; padding: 20px 30px;" *ngIf="this.eventStore.loading">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="80%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="50%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
            </div>
        </div>

        <div class="eventContainer">
            <div class="eventTitleContainer">
                <h2>News</h2>
                <h2 class="seeMoreLink" [routerLink]="['/news']">See More</h2>
            </div>

            <div class="eventContentContainer" *ngIf="!this.newsStore.loading" [routerLink]="['/news/' + this.newsStore?.arrNews[0]?.key]">
                <div class="eventContentContainer_leftSide">
                    <h1>{{ this.newsStore?.arrNews[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'dd' }}</h1>
                    <h4>{{ this.newsStore?.arrNews[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'MMMM' }}</h4>
                </div>
                <div class="eventContentContainer_rightSide" style="border: none;">
                    <h4>{{ this.newsStore?.arrNews[0]?.created_at?.toDate() || this.currentTimeStamp | date : 'shortTime' }}</h4>
                    <p>{{ this.newsStore?.arrNews[0]?.name || "មិនមានព័ត៌មាន" }}</p>
                </div>
            </div>

            <div class="lazyLoadingContainer" style="width: 100%; padding: 20px 30px;" *ngIf="this.newsStore.loading">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="80%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="50%" height="1rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
            </div>
        </div>

    </div>
</div>
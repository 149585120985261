<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'ពត័មានរបស់សិស្ស'" [backgroundColor]="'radial-gradient( circle 732px at -23.9% -25.1%,  rgba(30,39,107,1) 6.1%, rgba(188,104,142,1) 100.2% )'"></app-introduction-page-block>
    

    <div class="studentInformationContainer">
        <div class="studentImageContainer">
            <img src="{{ this.image }}" alt="NGS - New Generation School" />
        </div>

        <div class='studentNameContainer'>
            <h2>{{this.en_name}}</h2>
            <h3>{{this.kh_name}}</h3>
        </div>

        <div class='studentSchoolContainer'>
            <h2>វិទ្យាល័យព្រះស៊ីសុវត្តី (​​​ជំនាន់ថ្មី)</h2>
            <h4>{{ this.academic_year }} | {{ this.class }} | {{ this.puc_id }}</h4>
        </div>

        <div class="studentInformationDetailContainer">
            <h3>ពត័មានទូទៅរបស់សិស្ស</h3>

            <div class="studentInformationDetailBlock">
            <div class="studentInformationDetailItem"><h4>ឈ្មោះ :</h4></div>
            <div class="studentInformationDetailItem"><p>{{this.en_name}}</p></div>
            <div class="studentInformationDetailItem"><h4>ឡាតាំង :</h4></div> 
            <div class="studentInformationDetailItem"><p>{{this.kh_name}}</p></div>
            <div class="studentInformationDetailItem"><h4>ភេទ :</h4></div> 
            <div class="studentInformationDetailItem"><p>{{this.gender}}</p></div>
            <div class="studentInformationDetailItem"><h4>ថ្ងៃខែឆ្នាំកំណើត :</h4></div> 
            <div class="studentInformationDetailItem"><p>{{this.dob}}</p></div>
            <div class="studentInformationDetailItem"><h4>ទូរសព្ទ័ផ្ទាល់ខ្លូន :</h4></div> 
            <div class="studentInformationDetailItem"><p>{{this.personal_tel}}</p></div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss']
})
export class AnnouncementCardComponent implements OnInit {

  defaultImage = '../../../../assets/images/loading.gif';

  @Input() title;
  @Input() subTitle;
  @Input() image;
  @Input() key;

  constructor() { }

  ngOnInit(): void {
  }

}

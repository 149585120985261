import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-are-social',
  templateUrl: './we-are-social.component.html',
  styleUrls: ['./we-are-social.component.scss']
})
export class WeAreSocialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss']
})
export class Header2Component implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this.Closed = false;
    });
  }

  Closed = false;

  hamburgurClicked() {
    let element = document.querySelector('#header');
    let element3_1 = document.querySelector('#line-menu1');
    let element3_2 = document.querySelector('#line-menu2');
    let element3_3 = document.querySelector('#line-menu3');
    this.Closed = !this.Closed;

    if (this.Closed) {
      element.classList.add('navbar-inverse');
      element3_1.classList.add('line-menu_inverse');
      element3_2.classList.add('line-menu_inverse');
      element3_3.classList.add('line-menu_inverse');
    } else {
      element.classList.remove('navbar-inverse');
      element3_1.classList.remove('line-menu_inverse');
      element3_2.classList.remove('line-menu_inverse');
      element3_3.classList.remove('line-menu_inverse');
    }
  }

}

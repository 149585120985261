<div id="introductionContainer">

    <!-- <div class="bg-image">
        <img src="/assets/images/IMG_5918.JPG">
    </div> -->

    <div class="introductionContentContainer">
       <!-- <div class="introductionContent_text">
            <span class="light">LIVE</span>
            <span class="bold">LEARN</span>
            <span class="light">CREATE</span>
        </div> -->

        <div class="introductionContent_text">
            <span class="khmer-light">វិទ្យាល័យព្រះស៊ីសុវត្ថិ (សាលារៀនជំនាន់ថ្មី)</span>
        </div>

        <div class="introductionContent_subtext">
            <p>សាលារៀនជំនាន់ថ្មីនៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ(NGS)គឺជាគំរូសាលារៀនមួយស្ថិតនៅក្នុងវិទ្យាល័យ​​ធំជាងគេ<br/>និង មានវ័យចំណាស់ជាងគេនៅក្នុងប្រទេសកម្ពុជាមានអាយុកាលជាង១១៣ឆ្នាំ</p>
            <!-- <p>ប្រព័ន្ធអប់រំ អំពីសាលា គណនាយក ដៃគូរសហការ ពត៎មាន ប្រព័ន្ធសិក្សាពីចម្ងាយ</p> -->
        </div>

        <!-- <div class="introductionContent_boxes">
            <div class="introductionContent_box">
                <img src="/assets/images/big-picture-advertisement-image1.jpg" />
                <h1>Enquire Now</h1>
                <p>Learn about literal arts</p>
            </div>
            <div class="introductionContent_box">
                <img src="/assets/images/big-picture-advertisement-image2.jpg" />
                <h1>Schedule a Tour</h1>
                <p>See what our students are doing</p>
            </div>
            <div class="introductionContent_box">
                <img src="/assets/images/big-picture-advertisement-image3.jpg" />
                <h1>Call Us Today ...</h1>
                <p>Bill Gates ...</p>
            </div>
        </div> -->
    </div>
    
</div>
<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'ដៃគូរសហការ'" [backgroundColor]="'linear-gradient( 109.1deg,  rgba(181,73,91,1) 7.1%, rgba(225,107,140,1) 86.4% )'"></app-introduction-page-block>

    <div class="cardsContainer" style="grid-template-columns: repeat(4, 1fr);">
        <app-partnership-card *ngFor="let item of this.partnershipStore.data" 
        [title]="item.name" [image]="item.files[0].downloadUrl"></app-partnership-card>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.partnershipStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.partnershipStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.partnershipStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.partnershipStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    </div>
</div>
import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { KeywordGeneratorService } from "../services/generator/keyword-generator.service";
import { pushToArray, pushToObject } from "../services/data/data-manipulation.service";

@Injectable({providedIn:'root'})
export class NewsStore {
  @observable public arrNews: Array<any> = [];
  @observable public news: any;

  @observable data: any = [];
  @observable loading = false;
  public lastVisible: any = null;
  @observable public fetching: boolean = false;
  @observable public searchText = null;
  @observable public filter = null;

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  @action
  getNews() {
    this.loading = true;
    this.afs.collection('stores').doc('ngs').collection('apps_news', ref => ref.orderBy('created_at', 'desc').limit(1)).valueChanges().subscribe((data: any) => {
      this.arrNews = data;
      this.loading = false;
    });
  }

  @action 
  async getNewsDetails(newsKey: string) {
    try {
      console.log(this.loading)
      this.loading = true;
      const data = pushToObject(await this.afs.collection('stores').doc('ngs').collection('apps_news').doc(newsKey).get().toPromise());
      this.news = data;
      this.loading = false;
      console.log(this.loading)

      // this.loading = true;
      // this.afs.collection('stores').doc('ngs').collection('apps_news').doc(newsKey).valueChanges().subscribe((data: any) => {
      //   this.news = data;
      //   this.loading = false;
      // });
    } catch(error) {
      console.log(error)
    }
  }

  @action
  async fetchData() {
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;

    const ref = this.lazyDataRef(this.lastVisible);

    ref.auditTrail().subscribe();
    ref.snapshotChanges().subscribe(response => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }

      console.log(this.data)

      this.loading = false
      this.fetching = false;
    }, error => {
      this.loading = false;
    });
  }

  @action
  async fetchDataMore() {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data());
      }

      console.log(this.data)

      this.fetching = false;
    }, error => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any) {
    return this.afs.collection('stores').doc('ngs').collection<any>("apps_news", ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(10);

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      
      return condition
    })
  }
}

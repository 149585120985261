import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-main2-layout',
  templateUrl: './front-main2-layout.component.html',
  styleUrls: ['./front-main2-layout.component.scss']
})
export class FrontMain2LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<footer id="footer">
    <div class="footer-container">
        <div class="first-row">
            <div class="first-column">
                <div class="logoTitleContainer">
                    <div class="logoContainer">
                        <img src="/assets/images/NGS.png" id="header_image">
                    </div>
                    <div class="logoTitle" id="logoTitle" >
                        <p>វិទ្យាល័យព្រះស៊ីសុវត្ថិ</p>
                        <p>សាលារៀនជំនាន់ថ្មី</p>
                    </div>
                </div>

                <div class="blockContent">
                    <p>វិទ្យាល័យព្រះស៊ីសុវត្ថិ សាលារៀនជំនាន់ថ្មី (ទំនាក់ទំនងការិយាល័យ អាគារ D)</p>
                    <p>មហាវិថី ព្រះនរោត្តម, សង្កាត់ផ្សារថ្មី៣(១២២១០), ខណ្ឌដូនពេញ(១២២០០), ភ្នំពេញ, កម្ពុជា</p>
                </div>
            </div>
            <div class="second-column" style="width: 70%;">
                <div class="logoSubtitleContainer">
                    <p>
                        សាលារៀនជំនាន់ថ្មីនៃវិទ្យាល័យព្រះស៊ីសុវត្ថិ(NGS)គឺជាគំរូសាលារៀនមួយស្ថិតនៅក្នុងវិទ្យាល័យ​​ធំជាងគេនិងមានវ័យចំណាស់ជាងគេនៅក្នុងប្រទេសកម្ពុជាមានអាយុកាលជាង១១៣ឆ្នាំ។សាលារៀននេះ
                        ជាលទ្ធផល' មួយទៀតនៃការធ្វើកំណែទម្រង់ស៊ីជម្រៅលើវិស័យអប់រំ ដែលបានសម្ភោធដាក់ឱ្យដំណើរការ​នៅទី២៣ ខែ មិថុនា ឆ្នាំ ២០១៦ មាន២៤ថ្នាក់រៀនចែកជាបន្ទប់រៀនគីមីវិទ្យាបំពាក់ដោយ​
                        សម្ភារៈ បរិក្ខារពិសោធ ចំនួន០៣បន្ទប់ បន្ទប់រៀនរូបវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខារពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀន​​​​​ជី​វវិទ្យាបំពាក់ដោយសម្ភារៈបរិក្ខាពិសោធចំនួន០៣បន្ទប់បន្ទប់រៀនកុំព្យូទ័រ
                        បំពាក់ដោយ​ម៉ាស៊ីនកុំព្យូទ័រ​​ទំនើប ចំនួន០២បន្ទប់ស្មើ៦០គ្រឿងបន្ទប់គណិតវិទ្យា ០៤ បន្ទប់រៀន​ពោពេញ​ដោយសម្ភារឧប្បទេស ចាប់ពីថ្នាក់ទី ៧ ដល់ថ្នាក់ទី​១២ ​ គ្រូបង្រៀន សរុបចំនួន 
                        ៥៨នាក់ក្នុងនោះស្រី ៣០នាក់។
                    </p>
                </div>
            </div>
        </div>
        <div class="second-row">
            <p>Copyright 2020. All Right Reserved.</p>
            <p><a href="https://www.facebook.com/auxswot" target="_blank" style="text-decoration: none; color: white;">Powered by: Auxswot</a></p>
        </div>
    </div>
</footer>
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AnnouncementStore } from 'src/app/stores/announcement.store';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {

  constructor(
    public announcementStore: AnnouncementStore
  ) { }

  ngOnInit(): void {
    this.announcementStore.fetchData();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsStore } from 'src/app/stores/events.store';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  defaultImage = '../../../../assets/images/loading.gif';

  constructor(
    public store: EventsStore,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.getEventDetails(params.key)
    });
  }

  getImageFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return;
    return arrFiles.filter((item) => item?.fileType?.includes('image'));
  }

  getPDFFilesFromFiles(arrFiles: Array<any>) {
    if (!arrFiles) return;
    return arrFiles.filter((item) => item?.fileType?.includes('pdf'));
  }

  getDownloadableFiles(arrFiles: Array<any>) {
    if (!arrFiles) return;
    return arrFiles.filter((item) => !item?.fileType?.includes('pdf') && !item?.fileType?.includes('image'));
  }

}

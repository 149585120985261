import { Component, OnInit } from '@angular/core';
import { PartnershipStore } from 'src/app/stores/partnership.store';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {

  constructor(
    public partnershipStore: PartnershipStore
  ) { }

  ngOnInit(): void {
    this.partnershipStore.fetchData();
  }

}

<div id="detailPageWrapper">
    <app-introduction-page-block [title]="'ព្រឹត្តិការណ៍'" [backgroundColor]="'radial-gradient( circle 1294px at -15.5% 23.8%,  rgba(255,206,149,1) 0%, rgba(247,92,92,1) 44.9%, rgba(108,0,96,0.97) 93.8% )'"></app-introduction-page-block>

    <div class="cardsContainer">
        <app-events-card *ngFor="let item of this.eventStore.data" [title]="item.name" [image]="item.files[0].downloadUrl" [key]="item.key"></app-events-card>

        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.eventStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.eventStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    
        <div class="lazyLoadingContainer" style="width: 100%; padding: 20px;" *ngIf="this.eventStore.loading">
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="80%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="100%" height="1rem"></p-skeleton>
        </div>
    </div>
</div>
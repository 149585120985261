import { Component, OnInit } from '@angular/core';
import { VideoStore } from 'src/app/stores/video.store';

@Component({
  selector: 'app-second-description-block',
  templateUrl: './second-description-block.component.html',
  styleUrls: ['./second-description-block.component.scss']
})
export class SecondDescriptionBlockComponent implements OnInit {

  constructor(
    public videoStore: VideoStore
  ) { }

  ngOnInit(): void {
    this.videoStore.getVideos();
  }

  getYoutubeId(url: string) {
    return url.replace('https://www.youtube.com/watch?v=', '');
  }

}

<div id="detailPageWrapper">
    <div class="articleContainer">
        <div class="lazyLoadingContainer" style="width: 100%;" *ngIf="this.newsStore.loading">
            <p-skeleton width="80%" height="2rem"></p-skeleton>
            <div style="margin-top: 10px;"></div>
            <p-skeleton width="50%" height="1rem"></p-skeleton>
        </div>

        <div class="articleHeaderContainer" *ngIf="!this.newsStore.loading">
            <h1 class="articleTitle">{{ this.newsStore.news?.name }}</h1>
            <div class="articleInfoContainer">
                <p class="articleDate">Created Date: {{ this.newsStore.news?.created_at.toDate() | date : 'medium'}},</p>
                <p class="articleAuthor">Created By: {{ this.newsStore.news?.created_by | createdByRef : 'full_name' | async }}</p>
            </div>
        </div>

        <div class="articleContentContainer">
            <div class="leftSideBlock" *ngIf="this.newsStore.loading">
                <p-skeleton width="100%" height="40rem"></p-skeleton>
            </div>
            <div class="rightSideBlock" *ngIf="this.newsStore.loading">
                <p-skeleton width="100%" height="40rem"></p-skeleton>
            </div>

            <div class="leftSideBlock" [innerHTML]="this.newsStore.news?.description" *ngIf="!this.newsStore.loading"></div>
            <div class="rightSideBlock" *ngIf="!this.newsStore.loading">
                <div class="imageContainer">
                    <img *ngFor="let item of this.getImageFilesFromFiles(this.newsStore.news?.files)" [defaultImage]="defaultImage" [lazyLoad]="item.downloadUrl">
                </div>

                <p *ngIf="this.getPDFFilesFromFiles(this.newsStore.news?.files).length !== 0">Attachment PDFs</p>
                <ul>
                    <li *ngFor="let item of this.getPDFFilesFromFiles(this.newsStore.news?.files)">
                        <a href="{{ item.downloadUrl }}">{{ item.name }}</a>
                    </li>
                </ul>

                <p *ngIf="this.getDownloadableFiles(this.newsStore.news?.files).length !== 0">Attachment Files</p>
                <ul>
                    <li *ngFor="let item of this.getDownloadableFiles(this.newsStore.news?.files)">
                        <a href="{{ item.downloadUrl }}">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
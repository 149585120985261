<div id="thirdDescriptionContainer">

    <!-- <div class="bg-image">
        <img src="/assets/images/DSC08919.JPG">
    </div> -->

    <div class="descriptionContentContainer">
        <div class="descriptionTitle">
            <span class="light">កម្មវិធីសិក្សា</span><br>
            <span class="bold">របស់សាលា</span><br>
        </div>
        
        <div class="descriptionCardSwiperContainer">
            <app-card-swiper></app-card-swiper>
        </div>
    </div>
    
</div>


import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { pushToObject } from '../services/data/data-manipulation.service';

@Pipe({
  name: 'createdByRef'
})
export class CreatedByRefPipe implements PipeTransform {

  constructor(
    private afs: AngularFirestore
  ) {}

  async transform(userKey: any, field: string): Promise<unknown> {
    if (!userKey) return;
    const data = pushToObject(await this.afs.collection('users').doc(userKey).get().toPromise());
    return data[field];
  }

  // async transform(ref: any, field: string) {
  //   if (!ref) return;
  //   const data = pushToObject(await ref.get());
  //   return data[field];
  // }

}

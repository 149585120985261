import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isTop;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this.Closed = false;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('#header');
    let element2 = document.querySelector('#logoTitle');
    let element3_1 = document.querySelector('#line-menu1');
    let element3_2 = document.querySelector('#line-menu2');
    let element3_3 = document.querySelector('#line-menu3');
    
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
      element2.classList.add('logoTitle_inverse');
      element3_1.classList.add('line-menu_inverse');
      element3_2.classList.add('line-menu_inverse');
      element3_3.classList.add('line-menu_inverse');
      this.isTop = true;
    } else {
      element.classList.remove('navbar-inverse');
      element2.classList.remove('logoTitle_inverse');
      element3_1.classList.remove('line-menu_inverse');
      element3_2.classList.remove('line-menu_inverse');
      element3_3.classList.remove('line-menu_inverse');
      this.isTop = false;
    }
  }

  Closed = false;

  hamburgurClicked() {
    let element = document.querySelector('#header');
    let element3_1 = document.querySelector('#line-menu1');
    let element3_2 = document.querySelector('#line-menu2');
    let element3_3 = document.querySelector('#line-menu3');
    this.Closed = !this.Closed;

    if (this.Closed) {
      element.classList.add('navbar-inverse');
      element3_1.classList.add('line-menu_inverse');
      element3_2.classList.add('line-menu_inverse');
      element3_3.classList.add('line-menu_inverse');
    } else {
      if (!this.isTop) {
        element.classList.remove('navbar-inverse');
        element3_1.classList.remove('line-menu_inverse');
        element3_2.classList.remove('line-menu_inverse');
        element3_3.classList.remove('line-menu_inverse');
      }
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { NewsStore } from 'src/app/stores/news.store';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(
    public newsStore: NewsStore
  ) { }

  ngOnInit(): void {
    this.newsStore.fetchData();
  }

}

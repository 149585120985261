<swiper [config]="config" id="swiperContainer">

    <div class="slideContainer" *ngIf="this.schoolProgramStore.loading">
        <div class="top">
            <div class="for-image">
                <p-skeleton width="100%" height="300px"></p-skeleton>
            </div>
            <div class="for-content">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="5rem"></p-skeleton>
            </div>
        </div>
    </div>

    <div class="slideContainer" *ngIf="this.schoolProgramStore.loading">
        <div class="top">
            <div class="for-image">
                <p-skeleton width="100%" height="300px"></p-skeleton>
            </div>
            <div class="for-content">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="5rem"></p-skeleton>
            </div>
        </div>
    </div>

    <div class="slideContainer" *ngIf="this.schoolProgramStore.loading">
        <div class="top">
            <div class="for-image">
                <p-skeleton width="100%" height="300px"></p-skeleton>
            </div>
            <div class="for-content">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="5rem"></p-skeleton>
            </div>
        </div>
    </div>

    <div class="slideContainer" *ngIf="this.schoolProgramStore.loading">
        <div class="top">
            <div class="for-image">
                <p-skeleton width="100%" height="300px"></p-skeleton>
            </div>
            <div class="for-content">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
                <div style="margin-top: 10px;"></div>
                <p-skeleton width="100%" height="5rem"></p-skeleton>
            </div>
        </div>
    </div>

    <div class="slideContainer" *ngFor="let item of this.schoolProgramStore.arrSchoolProgram">
        <div class="top">
            <div class="for-image">
                <img src="{{ item?.files[0]?.downloadUrl }}">
            </div>
            <div class="for-content">
                <h1>{{ item.khName }}</h1>
                <h4>{{ item.khShort }}</h4>
            </div>
        </div>

        <div class="bottom">
            <p>Learn More</p>
        </div>
    </div>

</swiper>
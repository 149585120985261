import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontMainLayoutComponent } from './layouts/front-main-layout/front-main-layout.component';
import { FrontMain2LayoutComponent } from './layouts/front-main2-layout/front-main2-layout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AnnouncementDetailsComponent } from './pages/details-pages/announcement-details/announcement-details.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { EventDetailsComponent } from './pages/details-pages/event-details/event-details.component';
import { EventsComponent } from './pages/events/events.component';
import { HomeComponent } from './pages/home/home.component';
import { ManagementTeamComponent } from './pages/management-team/management-team.component';
import { NewsDetailsComponent } from './pages/details-pages/news-details/news-details.component';
import { NewsComponent } from './pages/news/news.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';
import { BarcodeProfileComponent } from './pages/barcode-profile/barcode-profile.component';

const routes: Routes = [
  {
    path: '',
    component: FrontMainLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      }
    ]
  },
  {
    path: '',
    component: FrontMain2LayoutComponent,
    children: [
      {
        path: 'news',
        component: NewsComponent
      },
      {
        path: 'news/:key',
        component: NewsDetailsComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'announcement',
        component: AnnouncementComponent
      },
      {
        path: 'announcement/:key',
        component: AnnouncementDetailsComponent
      },
      {
        path: 'event',
        component: EventsComponent
      },
      {
        path: 'event/:key',
        component: EventDetailsComponent
      },
      {
        path: 'management-team',
        component: ManagementTeamComponent
      },
      {
        path: 'partnership',
        component: PartnershipComponent
      },
      {
        path: 'profile',
        component: BarcodeProfileComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: '**',
        component: NotFoundPageComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

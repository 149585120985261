<div class="wearesocialContainer">
    <div class="columns">
        <div class="first-column">
            <!-- <span class="light">WE</span>
            <span class="light">ARE</span>
            <span class="bold">SOCIAL</span> -->

            <p class="light">WE</p>
            <p class="light">ARE</p>
            <p class="bold">SOCIAL</p>
        </div>
        <div class="second-column" style="width: 45%;">
            <h2>វិទ្យាល័យព្រះស៊ីសុវត្តិ ~ សាលារៀនជំនាន់ថ្មី</h2>
            <div class="content">
                <div class="blockContent">
                    <h4>លេខទូរសព្ទ</h4>
                    <p>(+855) 89 899 632 លោកនាយករង សម្យ កំសាន្ត</p>
                    <p>(+855) 70 399 606 លោកនាយករង ហួត ស៊ាងហៃ</p>
                    <p>(+855) 12 926 656 លោកនាយិកា ទ្រី​ វុិច</p>
                </div>
                <div class="blockContent">
                    <h4>អ៊ីមែល</h4>
                    <p class="enFont">info@preahsisowath.edu.kh</p>
                    <p class="enFont">preahsisowath@gmail.com.kh</p>
                </div>
                <!-- <div class="blockContent_2">
                    <h4>ហ្វេសប៊ុក</h4>
                    <p>New Generation School, Preah Sisowath</p>
                </div>
                <div class="blockContent_2">
                    <h4>តេឡេក្រាម</h4>
                    <p>089 899 632 or @Samkamsann</p>
                </div>
                <div class="blockContent_2">
                    <h4>គេហទំព័រ</h4>
                    <p>www.preahsisowath.edu.kh</p>
                </div> -->
            </div>
        </div>
        <div class="third-column" style="width: 25%;">
            <a href="https://www.youtube.com/channel/UCekJm_4CmOHhAoU6q-f6MHQ" style="text-decoration: none;" target="_blank">
                <button style="background-color: #FF0000;">
                    <img src="./assets/images/youtube.png">
                    <p>ទស្សនាវីដេអូផ្សេងៗ</p>
                </button>
            </a>

            <a href="https://www.facebook.com/ngssisowath" style="text-decoration: none;" target="_blank">
                <button style="background-color: #00477F;">
                    <img src="/assets/images/facebook-white.png">
                    <p>តាមដានពួកយើង</p>
                </button>
            </a>

            <div class="downloadContainer">
                <!-- <img src="./assets/images/availabel-download.png"> -->
                <a href="https://apps.apple.com/kh/app/ngs-mobile/id1504831179" target="_blank"><img src="./assets/images/app store.png"></a>
                <a href="https://play.google.com/store/apps/details?id=edu.ngs.mobile.auxswot&hl=en&gl=US" target="_blank"><img src="./assets/images/play store.png"></a>
            </div>
        </div>
    </div>
</div>